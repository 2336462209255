// --- Common

export const CONTACT = 'contact';
export const CONTACT_SUCCESS = 'success';

// --- Public pages
export const ROOT = '/';

// Case studies
export const CASE_STUDY = 'case-study';
export const CASE_STUDY_SLUG_PARAM = '[caseStudySlug]';
export const CASE_STUDY_PATH = `/${CASE_STUDY}/${CASE_STUDY_SLUG_PARAM}`;

// Contact
export const CONTACT_PATH = `/${CONTACT}`;
export const CONTACT_SUCCESS_PATH = `/${CONTACT}/${CONTACT_SUCCESS}`;

// --- Marketplace
export const MARKETPLACE = 'marketplace';
export const LANGUAGE_PARAM = '[language]';
export const MARKETPLACE_ROOT = `/${LANGUAGE_PARAM}`;

// Public profile
export const PUBLIC_PROFILE = 'profile';

// Contact
export const MARKETPLACE_CONTACT_PATH = `${MARKETPLACE_ROOT}/${CONTACT}`;
export const MARKETPLACE_CONTACT_SUCCESS_PATH = `${MARKETPLACE_ROOT}/${CONTACT}/${CONTACT_SUCCESS}`;

// Project
export const PROJECT = 'project';
export const PROJECT_SUCCESS = 'success';
export const PROJECT_BOOK_A_CALL = 'book-a-call';
export const MARKETPLACE_PROJECT_PATH = `${MARKETPLACE_ROOT}/${PROJECT}`;
export const MARKETPLACE_PROJECT_SUCCESS_PATH = `${MARKETPLACE_ROOT}/${PROJECT}/${PROJECT_SUCCESS}`;
export const MARKETPLACE_PROJECT_BOOK_A_CALL_PATH = `${MARKETPLACE_ROOT}/${PROJECT}/${PROJECT_BOOK_A_CALL}`;
